.footer {
  background-color: #263859;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: rgb(168, 168, 168);

  p {
    padding: 15px 0;
    font-size: 15px;
    background-color: #17223b;
    width: 100%;
    text-align: center;
  }

  .ft-links {
    display: flex;
    flex-direction: row;
    width: 90%;
    justify-content: safe;
    padding: 20px 0;

    .boxes {
      margin-right: 40px;
    }

    h3 {
      margin-bottom: 10px;
      width: 100%;
      font-size: 20px;
      color: #ff6768;
    }

    svg {
      color: #ff0000;
    }

    ul {
      li {
        font-size: 14px;
        list-style: none;

        a {
          text-decoration: none;
          color: rgb(224, 224, 224);

          span {
            margin-left: 10px;
          }

          &:hover {
            color: orange;
          }
        }
      }
    }
  }
}
