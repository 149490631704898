.dashboard {
  display: flex;
  justify-content: center;
  background-image: url(../images/dashboard.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  position: relative;
  align-items: center;

  .dash-pad {
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: center;
    // height: 20%;
    // width: 1060px;
    border-radius: 20px;

    align-items: center;

    .total_form {
      display: flex;
      position: relative;
      align-items: center;
      background: white;
      border-radius: 10px;
      padding: 0 10px 0 10px;
      box-shadow: 0px 3px 5px rgba(130, 130, 130, 0.441);
    }

    .toggler {
      display: flex;
      background-color: rgb(255, 255, 255);
      position: absolute;
      color: #6f6f6f;
      left: 260px;
      top: 17px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      border-radius: 50px;
      border: 2px solid gray;
      z-index: 10;
      box-shadow: 0px 0px 5px rgb(116, 116, 116);
      cursor: pointer;

      &:hover {
        width: 32px;
        height: 32px;
        color: green;
        border: 2px solid green;
      }
    }

    .top-text {
      text-align: center;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
    }

    .type_departure,
    .type_arrival {
      margin-right: 0px;

      // border-right: 1px solid rgb(198, 198, 198);
      display: flex;
      flex-direction: column;

      ul {
        background-color: rgb(255, 255, 255);

        display: flex;
        flex-direction: column;
        position: absolute;
        width: 300px;
        height: 200px;
        overflow: hidden;
        overflow-y: auto;
        z-index: 1;
        border: 1px solid rgb(134, 134, 134);

        li {
          list-style: none;
          cursor: pointer;

          padding: 10px;

          &:hover {
            background-color: pink;
          }
        }
      }

      ul::-webkit-scrollbar {
        display: none;
      }
    }

    input[type="text"] {
      padding: 15px 0 15px 35px;
      font-size: 22px;
      width: 300px;
      // margin-top: 10px;
      border: none;
      color: #424242;
      margin-right: 0;
    }

    #submit {
      padding: 10px 35px 10px 15px;
      font-size: 15px;
      border: none;
      border-radius: 50px;
      background-color: #ffc43a;
      color: rgb(67, 67, 67);
      cursor: pointer;
      position: relative;
      font-weight: 500;
      box-shadow: 0px 1px 10px rgba(153, 153, 153, 0.723);
      .srch-btn {
        position: absolute;
        right: 10px;
        top: 12px;
      }
    }

    input[type="text"]:focus {
      outline: none;
    }

    .top-text {
      display: none;
    }

    .type_departure,
    .type_arrival {
      position: relative;

      .drop,
      .pickup {
        position: absolute;
        left: 2px;
        top: 14px;
        font-size: 25px;
      }
      .pickup {
        left: 1px;
      }
    }

    .date {
      display: flex;
      flex-direction: column;
      position: relative;

      .material-symbols-outlined {
        position: absolute;
        top: 17px;
        left: -12px;
        font-size: 30px;
      }
    }

    .react-datepicker__close-icon {
      top: 2px;
      right: 13px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .dashboard {
    height: 400px;
    padding: 10px;
    justify-content: flex-start;

    .dash-pad {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding: 10px;
      width: 50%;
      height: 80%;
      border-radius: 10px;
      background-color: white;
      justify-content: flex-start;

      .total_form {
        flex-direction: column;
        background: none;
        border-radius: 0;
        border: 0;
        padding: 0;
        align-items: stretch;
        box-shadow: none;

        .span {
          display: none;
        }

        .type_departure,
        .type_arrival {
          width: 100%;
          border: none;
          box-shadow: 0px 3px 4px solid #999;
          ul {
            display: flex;
            width: 100%;
            margin-top: -10px;
            border: 0;
            background-color: #f0f0f0;
            box-shadow: 0px 4px 9px rgb(181, 181, 181);
          }
        }
      }

      input[type="text"] {
        padding: 10px 0 15px 35px;
        width: 100%;
        margin-bottom: 30px;
        // border-radius: 10px;
        border-bottom: 1px solid #cecece;
        font-size: 16px;
      }

      .submit {
        // position: absolute;
        // bottom: -20px;
        /* left: 25%;
        right: 25%;
        margin: auto; */
        align-content: center;

        #submit {
          padding: 10px 70px;
          border-radius: 0;
          font-size: 17px;
          border: none;
          background-color: #ffc43a;
          color: white;
          cursor: pointer;
          width: 100%;

          .srch-btn {
            position: relative;
            top: 2px;
            left: 10px;
          }
        }
      }

      .date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 10px;

        .material-symbols-outlined {
          position: absolute;
          top: 12px;
          left: 0px;
          font-size: 22px;
        }
      }

      .toggler {
        transform: rotate(90deg);
        top: 30px;
        width: 50px;
        box-shadow: 0px 0px 10px rgba(255, 166, 0, 0.604);
        border: none;

        &:hover {
          width: 50px;
          border: 0;
        }
      }
      .type_departure,
      .type_arrival {
        position: relative;

        .drop,
        .pickup {
          position: absolute;
          left: 5px;
          top: 6px;
          font-size: 22px;
        }
        .pickup {
          left: 5px;
        }

        .material-symbols-outlined {
          position: absolute;
          left: 10px;
          top: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .dashboard {
    height: 300px;

    .dash-pad {
      flex-direction: column;

      border-radius: 0px;
      padding: 0;
      position: relative;
      height: 88%;

      box-shadow: 0px 0px 8px rgba(199, 199, 199, 0.24);
      align-items: stretch;
      width: 100%;
      border-radius: 5px;

      .top-text {
        text-align: center;
        margin-bottom: 10px;
        font-size: 20px;
        font-weight: bold;
      }

      .total_form {
        border: 0;
        padding: 15px 10px;
        background: white;
        align-items: stretch;
      }

      .toggler {
        display: flex;
        background-color: rgb(255, 255, 255);
        position: absolute;
        color: #6f6f6f;
        left: 200px;
        top: 45px;
        transform: rotate(90deg);
        width: 40px;
        height: 40px;
        align-items: center;
        justify-content: center;
        font-size: 22px;
        border-radius: 50px;
        border: 2px solid gray;
        z-index: 1;
        box-shadow: 0px 0px 10px rgb(216, 180, 0);
        cursor: pointer;

        &:hover {
          width: 40px;
          height: 40px;
          color: green;
          border: 2px solid green;
        }
      }

      .type_departure,
      .type_arrival {
        position: relative;

        .drop,
        .pickup {
          position: absolute;
          left: 0px;
          top: 12px;
          font-size: 22px;
        }
        .pickup {
          left: 0px;
        }

        .material-symbols-outlined {
          position: absolute;
          left: 10px;
          top: 12px;
        }
      }

      input[type="text"] {
        padding: 15px 0 15px 35px;
        font-size: 16px;
        width: 100%;
        margin-bottom: 10px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #e0e0e0;
      }

      .date {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 10px;

        .material-symbols-outlined {
          position: absolute;
          top: 12px;
          left: 0px;
          font-size: 22px;
        }

        p {
          text-transform: uppercase;
          font-size: 13px;
          background-color: #5856d6;
          padding: 10px 20px;
          border-radius: 10px;
          color: white;
          cursor: pointer;
        }
      }

      .react-datepicker__close-icon {
        top: -10px;
        right: 10px;
      }

      .submit {
        // position: absolute;
        // bottom: -20px;
        /* left: 25%;
        right: 25%;
        margin: auto; */
        align-content: center;

        #submit {
          padding: 10px 70px;
          border-radius: 0;
          font-size: 17px;
          border: none;
          background-color: #ffc43a;
          color: white;
          cursor: pointer;
          width: 100%;

          .srch-btn {
            position: relative;
            top: 2px;
            left: 10px;
          }
        }
      }
    }
  }
}
