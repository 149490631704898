@media screen and (max-width: 1060px) {
  /* ----------------Contact Us--------------- */

  .contact-main {
    width: 95%;
    .contact-container {
      min-height: 100vh;
      flex-direction: column;
      align-items: flex-start;

      .contact-info {
        width: 100%;
        padding: 10px 0 0 0;
        justify-content: flex-start;

        .getintouch {
          margin-bottom: 30px;
          h1 {
            font-size: 22px;
            text-align: center;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
          }

          p > strong {
            color: rgb(255, 0, 0);
            font-size: 14px;
          }

          .mob,
          .eml {
            font-size: 15px;
          }
        }
      }

      .form-wrapper {
        width: 100%;
        padding: 30px 0 10px 0;

        .forms {
          width: 100%;
          padding: 20px;
        }
      }
    }
  }

  /* --------------section.Js--------------- */

  .carousel {
    max-width: 100%;
  }

  /* ----------------Info.Js-------------- */

  .info {
    width: 98%;
  }
}

@media screen and (max-width: 720px) {
  /* ---------------Header.Js--------------*/

  .header-main {
    background-color: #fff;
    box-shadow: 0px 0px 5px rgba(204, 204, 204, 0.33);
  }

  /* -------------Section.Js-------------- */

  .section {
    margin: 40px 0;
    width: 100%;
    padding: 5px;

    p {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }

    .section-wrapper {
      display: flex;
      flex-direction: row;
      overflow-x: scroll;
      justify-content: flex-start;

      .box {
        width: 300px;
        margin: 5px;
        overflow: visible;

        img {
          width: 300px;
        }
      }
    }
  }

  /* ----------------Info.Js----------------- */

  .info {
    margin: 0 auto;
    width: 97%;

    .info-wrapper {
      padding: 10px;
    }
  }

  /* ----------Footer.Js--------------- */

  .footer {
    .ft-links {
      flex-direction: column;

      h3 {
        width: 70%;
      }

      .boxes {
        margin-bottom: 40px;
      }
    }
  }

  /* -----------Contact-Us.Js------------- */

  .contact-main {
    width: 95%;
    .contact-container {
      min-height: 100vh;
      flex-direction: column-reverse;
      align-items: flex-start;

      .contact-info {
        width: 100%;
        padding: 10px 0 0 0;
        justify-content: flex-start;

        .getintouch {
          margin-bottom: 30px;
          h1 {
            font-size: 22px;
            text-align: center;
            margin-bottom: 10px;
          }

          p {
            font-size: 14px;
          }

          p > strong {
            color: rgb(255, 0, 0);
            font-size: 14px;
          }

          .mob,
          .eml {
            font-size: 15px;
          }
        }
      }

      .form-wrapper {
        width: 100%;
        padding: 30px 0 10px 0;

        .forms {
          width: 100%;
          padding: 20px;
        }
      }
    }
  }
}
