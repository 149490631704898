.header-main {
  background-color: #f9dbdd;

  .margin-lr {
    width: 90%;
    margin: 0 auto;
  }
  .nav-bar {
    display: flex;

    justify-content: space-between;
    align-items: center;

    .left-nav {
      padding: 5px 0;

      img {
        width: 180px;
      }
    }

    .right-nav {
      display: flex;
      ul {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px 0;
        height: 100%;
        width: 100%;

        li {
          margin-left: 20px;
          a {
            color: rgb(36, 36, 36);
            margin-top: 0;
            text-decoration: none;
            padding: 20px 10px;
            width: 100%;
            height: 100%;
            font-size: 18px;

            &:hover {
              color: #ff9500;
            }
          }
        }
      }
    }
  }
}

.mobile-menu {
  display: none;
}

.nav-menu {
  display: none;
}

@media screen and (max-width: 1060px) {
  .header-main {
    background-color: white;
    .nav-bar {
      .right-nav {
        display: none;
      }
    }

    .mobile-menu {
      display: flex;
      .menu-bars {
        font-size: 40px;
        color: #372728;
      }
    }

    .nav-menu {
      background-color: #fd9b10;
      width: 300px;
      height: 100vh;
      display: flex;

      position: absolute;
      left: -300px;
      top: 0;
      transition: 850ms;
      z-index: 99;
    }

    .nav-menu.active {
      left: 0;
      transition: 350ms;
    }

    .nav-menu-items {
      width: 100%;

      .navbar-toggle {
        width: 95%;
        height: 60px;
        display: flex;
        position: absolute;
        justify-content: flex-end;
        align-items: center;
        color: rgb(255, 255, 255);

        .menu-bars {
          font-size: 22px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: white;
          border-radius: 100%;
          height: 35px;
          width: 35px;
          color: rgb(255, 255, 255);
          box-shadow: 0px 5px 9px rgb(255, 82, 82);
        }
      }
    }

    li {
      list-style: none;
      width: 150px;
      padding: 10px;
      border-radius: 10px;
      text-align: center;
      user-registration {
        margin-top: 20px;
        margin-left: 20px;
      }
    }

    svg {
      color: rgb(0, 0, 0);
    }

    .user-registration {
      background-image: linear-gradient(
        90deg,
        rgb(34, 80, 132),
        rgb(34, 80, 132)
      );
      margin-top: 20px;
      margin-left: 20px;
      color: rgb(250, 250, 250);
    }

    .nav-text {
      display: flex;
      justify-content: stretch;
      align-items: center;
      padding: 8px 0px 8px 16px;
      list-style: none;
      height: 40px;
      margin-top: 10px;
      width: 100%;

      a {
        text-decoration: none;
        color: #f8f8f8;
        font-size: 16px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;

        &:hover {
          background-color: gray;
        }

        span {
          margin-left: 10px;
        }
      }
    }
  }
}
