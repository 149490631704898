.contact-main {
  min-height: 100vh;
  width: 90%;
  margin: 0 auto;

  .contact-container {
    display: flex;

    min-height: 90vh;

    align-items: center;

    .contact-info {
      width: 50%;

      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;

      .getintouch {
        .people {
          display: flex;
          align-items: center;
        }
        span {
          margin-right: 10px;
          img {
            width: 30px;
          }
        }
        .mob::before,
        .eml::before,
        .addrs::before {
          font-family: "Font Awesome\ 5 Free";
          font-weight: 700;
          margin-right: 10px;
          font-size: 21px;
        }

        .mob::before {
          content: "\f095";
        }

        .eml::before {
          content: "\f0e0";
        }

        .addrs::before {
          content: "\f015";
        }
      }
    }

    .form-wrapper {
      width: 690px;
      padding: 10px;
      display: flex;
      justify-content: center;

      .forms {
        display: flex;
        flex-direction: column;
        width: 70%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        padding: 30px;
        border-radius: 10px;

        input[type="text"],
        input[type="email"],
        input[type="submit"],
        .message {
          padding: 20px;
          margin-bottom: 10px;
          border: none;
          border-radius: 8px;
          background-color: rgb(233, 233, 233);
        }

        input[type="submit"] {
          margin-bottom: none;
          padding: 15px;
          background-image: linear-gradient(
            90deg,
            rgb(4, 131, 42),
            rgb(0, 195, 94)
          );
          color: white;
          font-size: 22px;
          font-weight: bold;
        }

        .message {
          resize: none;
        }

        .notes {
          margin-top: 10px;
          p {
            font-size: 13px;
            color: #333;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}
