.container-buses {
  width: 80%;
  margin-top: 6.5%;
  // height: 1000px;
  display: grid;
  grid-template-columns: 25% 70%;

  grid-gap: 3%;
  margin: 10px auto;

  .filterContainer {
    width: 100%;

    // height: 1000px;

    .bdy {
      width: 140px;
      img {
        width: 20px;
      }
    }
  }

  .available-bus-container {
    // border: 1px solid black;
    width: 100%;
    /* height: auto; */
    display: grid;
    grid-gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    top: 0;
  }

  .sortdiv {
    width: 100%;
    height: 50px;
    border: 1px solid black;
    display: grid;
    grid-template-columns: 18% 10% 10% 10% 10% 10% 10% 10%;
    grid-gap: 10px;
  }
}
