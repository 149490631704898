.carousel {
  max-width: 90%;
  margin: 0 auto;
  cursor: grab;
  overflow: hidden;

  .inner-carousel {
    display: flex;

    .item {
      min-width: 300px;
      min-height: 140px;
      margin: 10px;

      img {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
  }
}
