.notfound-container {
  flex-wrap: wrap;
  height: 80vh;
  display: flex;
  justify-content: center;

  .notfound-wrapper {
    display: flex;
    flex-direction: column;
    width: 450px;
    align-self: center;
    flex-wrap: wrap;
    padding: 0 10px 0 10px;

    h2 {
      text-transform: uppercase;
      font-weight: bold;
      font-size: 150px;
      text-align: center;
      font-family: dosis;
    }

    .err {
      color: gray;
      margin-bottom: 20px;
      text-transform: capitalize;
    }

    p {
      color: rgb(103, 103, 103);
      text-align: center;
    }

    .btn {
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        text-transform: uppercase;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 4px 7px 0px rgba(68, 68, 68, 0.11);
        border: 1px solid red;
        display: flex;
        width: 150px;
        border-radius: 10px;
        justify-content: center;
        cursor: pointer;

        a {
          text-decoration: none;
          color: black;
          width: 100%;
          text-align: center;
          padding: 10px 20px;

          &:hover {
            color: white;
            transition: 300ms ease-in-out;
          }
        }

        &:hover {
          background-color: red;
          transition: 300ms ease-in-out;
        }
      }
    }
  }
}
