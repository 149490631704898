.main_container {
  right: 0;
  height: 100vh;
  background: #fff;
  color: #4a4a4a;
  position: fixed;
  opacity: 1;
  top: 0;
  /* right: -50%; */
  width: 45%;
  z-index: 10001;
  transition: all 0.5s ease-in;
  box-shadow: -5px 0 5px -3px #949393;
  padding: 20px;
}
.backarw {
  float: left;
  cursor: pointer;
}
.heading {
  text-align: center;
  font-size: 19px;
  margin: 0;
  padding-bottom: 2px;
}
.container {
  height: 80vh;
  overflow-y: auto;
  border-bottom: 1px solid #ccc;
}
.headingdet {
  padding: 0.5em;
  font-weight: 600;
  color: #1c1c1c;
  height: 50px;
}
.title {
  padding-top: 3px;
  float: left;
}
.pasdet {
  box-shadow: 0 0 4px 0 hsl(0deg 0% 64% / 50%);
  padding: 1em 1em 0.5em;
  background: #fff;
  margin-bottom: 0.5em;
  position: relative;
}
.detail1 {
  box-shadow: 0 0 4px 0 hsl(0deg 0% 64% / 50%);
  padding: 1em 1em 0.5em;
  background: #fff;
  margin-bottom: 0.5em;
  position: relative;
}
.passeat {
  color: #4a4a4a;
  position: relative;
}
.pass1 {
  margin-right: 0.5em;
  padding-right: 0.5em;
}
.seats {
  display: inline-block;
  border-left: 2px solid #b2b2b2;
  padding-left: 0.8em;
}
.namediv {
  width: 100%;
  margin-bottom: 0.8em;
  height: 70px;
}
.namelbl {
  display: inline-block;
  font-size: 16px;
  text-align: left;
  color: rgba(74, 74, 74, 0.86);
  width: 100%;
}
.inpblock {
  width: 100%;
  background: #fff;
  line-height: 1.2em;
  padding: 0.5em;
  border-radius: 3px;
  border: 1px solid #bababa;
  outline: none;
  font-size: 18px;
  margin: 0.8em 0 0;
  color: rgba(74, 74, 74, 0.86);
}
.agendiv {
  width: 100%;
  display: flex;
  height: 5.5em;
  clear: left;
}
.gendiv {
  width: 60%;
  vertical-align: top;
}
.gendiv > span {
  font-size: 0.875em;
  text-align: left;
  color: rgba(74, 74, 74, 0.86);
  display: inline-block;
  margin-bottom: 0.5em;
}
.male {
  position: absolute;
  width: 62px;
  height: 18px;
}
.malebx,
.femalebx {
  width: 22px;
  height: 17px;
  vertical-align: middle;
  /* float: left; */
}
.malelabel {
  padding: 0 0.45em;
  margin: 0 20px 0 0;
  float: left;
  font-size: 14px;
}
.female {
  width: 81px;
  position: absolute;
  height: 18px;
}
.agediv {
  margin-left: 110px;
  width: 30%;
  display: inline-block;
  margin-bottom: 1em;
}
.topdiv {
  padding-top: 0;
  padding: 0.5em;
  height: 50px;
  font-weight: 600;
  color: #1c1c1c;
}
.lowdiv {
  box-shadow: 0 0 4px 0 hsl(0deg 0% 64% / 50%);
  position: relative;
  padding: 1em;
  background: #fff;
}
.msgg {
  margin-bottom: 1.5em;
  color: #333;
  background-color: #fff181;
  border-radius: 5px;
  font-size: 12px;
  padding: 3px 10px;
  width: 75%;
}
.phn {
  display: inline-block;
  font-size: 0.875em;
  text-align: left;
  color: rgba(74, 74, 74, 0.86);
  width: 100%;
}
.nusel {
  margin-right: 0.8em;
  background: #fff;
  padding: 0.5em;
  width: 18%;
  border: 1px solid #bababa;
  border-radius: 3px;
  color: #4f91d9;
  outline: none;
  position: relative;
  transition: all 0.3s;
  font-size: 14px;
  margin-top: 0.8em;
  height: 2.8em;
}
