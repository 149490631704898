.info {
  padding: 10px;
  background-color: white;
  width: 90%;
  margin: 0 auto;

  .info-wrapper {
    padding: 20px;

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
      color: rgb(32, 0, 64);
      margin-top: 5px;
    }

    p {
      font-size: 15px;
      margin-bottom: 30px;
      color: rgb(105, 105, 105);
      // letter-spacing: 1px;
      line-height: 1.5;
    }

    ul {
      font-size: 15px;
      margin-left: 20px;
      color: rgb(100, 100, 100);

      li {
        margin-bottom: 5px;
        padding-left: 10px;

        &::marker {
          content: "\2714";
          color: orange;
          font-weight: bold;
        }
      }
    }
  }
}
